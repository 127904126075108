import * as React from 'react'
import { Box } from 'grid-styled'
import styled from 'styled-components'

import { Button } from './Button'
import Input from './Input'

const Card = styled.div`
  padding: 1.5rem;
  border-radius: 4px;
  background-color: #fff;
  margin: 0 1rem 2rem;
  box-shadow: 0 6px 18px 0 rgba(46, 50, 12, 0.15);
`

interface Props {
  form?: boolean
  title: string
  children?: React.ReactNode
}

export default ({ form, title, children, ...rest }: Props) => (
  <Card {...rest}>
    <h4>{title}</h4>
    {children}
    {form && (
      <form>
        <Input
          type="email"
          name="email"
          placeholder="Email address"
          required={true}
        />
        <Box mt={3}>
          <Button inverted={true} full={true} type="submit">
            Subscribe
          </Button>
        </Box>
      </form>
    )}
  </Card>
)
