import * as React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

import Layout from '../components/Layout'
import { xPad, yPad } from '../utils/theme'
import Card from '../components/CTACard'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import Button from '../components/Button'

const Article = styled.div``

const ArticleHeader = styled.header`
  position: relative;
  padding-top: 7rem;
  padding-bottom: 3.5rem;
  background-color: #141414;

  .gatsby-image-outer-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  @media (min-width: 600px) {
    padding-top: 12rem;
    padding-bottom: 4.5rem;
  }

  @media (min-width: 940px) {
    padding-top: 16rem;
    padding-bottom: 6rem;
  }

  @media (min-width: 1180px) {
    padding-top: 20rem;
    padding-bottom: 7rem;
  }

  @media (min-width: 1440px) {
    padding-top: 25rem;
    padding-bottom: 8.5rem;
  }
`

const HeaderTitle = styled.h1`
  color: #fff;
  position: relative;
  z-index: 1;
  text-shadow: 0 1px 2px rgba(20, 20, 20, 0.15);

  @media (min-width: 600px) {
    padding-right: 1.5rem;
  }
`

const Container = styled.div`
  width: 100%;
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  max-width: 1220px;
  margin: 0 auto;
  max-width: 850px;

  ${xPad};
`

interface IFigureProps {
  image: string
}

const Figure = styled<IFigureProps, any>(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0.5;

  & > img {
    object-fit: cover !important;
    object-position: 0% 0% !important;
    font-family: 'object-fit: cover !important; object-position: 0% 0% !important;';
  }
`

const Meta = styled.div`
  border-bottom: 1px solid #dcdfe4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  @media (min-width: 940px) {
    margin-bottom: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  @media (min-width: 1440px) {
    margin-bottom: 2.5rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
`

const Body = styled.article`
  font-size: 1.2em;
  line-height: 1.8;
  ${yPad};
  padding-top: 0 !important;

  blockquote {
    opacity: 0.7;
    font-style: italic;
    border-left: 3px solid #ccc;
    padding-left: 24px;
  }

  a {
    color: ${(props) => props.theme.colors.primary};
    text-decoration: underline;
  }
`

const StyledCard = styled(Card)`
  margin: 0;
  margin-bottom: 7em;
`

// prettier-ignore
interface IProps {
  pathContext: {
    html: string;
    title: string;
    metaTitle: string;
    description: string;
    author: string;
    date: string;
    image: {
      childImageSharp: {
        fluid: object;
      }
    }
  }
}

const ArticleTemplate: React.SFC<IProps> = ({ pathContext }) => (
  <Layout header="light">
    <Helmet>
      <title>
        {pathContext.metaTitle ? pathContext.metaTitle : pathContext.title} -
        Just Patios Blog
      </title>
      {pathContext.description && (
        <meta name="description" content={pathContext.description} />
      )}
    </Helmet>

    <Article>
      <ArticleHeader>
        <Container>
          <HeaderTitle>{pathContext.title}</HeaderTitle>
        </Container>
        <Figure
          fluid={
            pathContext.image ? pathContext.image.childImageSharp.fluid : null
          }
        />
      </ArticleHeader>

      <Container>
        <Meta>
          <span>
            by {pathContext.author} • {pathContext.date}
          </span>
        </Meta>
      </Container>

      <Container>
        <Body dangerouslySetInnerHTML={{ __html: pathContext.html }} />
        <StyledCard title="Get in touch">
          <p>
            Need a builder to custom design and install patios, carports and
            enclosures in Brisbane? Call the professional builders at Just
            Patios today!
          </p>
          <Link to="/contact-us/">
            <Button inverted={true} full={true}>
              Enquire now
            </Button>
          </Link>
        </StyledCard>
      </Container>
    </Article>
  </Layout>
)

export default ArticleTemplate
